
import Vue from 'vue';
import App from './App.vue';
import store from '@/store';
import router from '@/router';
import 'lib-flexible'
import '@/plugins/index.js';
import '@/styles/index.scss';
import Vant from 'vant'
Vue.use(Vant)

import { Lazyload } from 'vant';
Vue.use(Lazyload)

//缓存
import cache from '@/utils/cache.js';
Vue.prototype.$cache = cache;

//公共key参数
import globalKeys from '@/utils/global-keys.js';
Vue.prototype.$globalKeys = globalKeys;

//全局loading
import ldloading from '@/utils/ld-loading.js';
Vue.prototype.$ldloading = ldloading;

//过滤器
import * as filters from './filters';
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 公共接口
import api from '@/api/index.js';
Vue.prototype.$api = api;

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wx-open-launch-weapp', 'wx-open-subscribe']

new Vue({
  store,
  router,
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  render: (h) => h(App),
}).$mount('#app');
