import cache from '@/utils/cache.js';

const state = {
  token: cache.get(cache.tokenKey),
};

const mutations = {
  setToken: (state, token) => {
    state.token = token;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
