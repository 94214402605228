<template>
    <!-- <router-view></router-view> -->
     <div class="active-box">
          <div class="fl86"></div>
          <van-image class="active-ludao flsh0"
              :src="require('./assets/active/ludao.png')"
              />
          <van-image class="active-logo flsh0"
          :src="require('./assets/active/logo.png')"
          />
          <div class="fl252"></div>
          <div class="active-text flsh0">点击下方按钮打开"什物纪鹿岛"</div>
          <van-button class="active-btn flsh0" type="success" @click="getWeChatScheme">打开小程序</van-button>
          <div class="fl206"></div>
    </div>
</template>

<script>

export default {
  name: 'App',
  data () {
      return {
          scheme: '',//小程序路径
          shortLink: '',//域名后面的n位字符串
          baseObj:{}
      }
  },
  async mounted() {
      this.baseObj = this.$cache.get('baseObj') ? JSON.parse(this.$cache.get('baseObj')) : {};
      this.shortLink = await this.urlFmt(window.location.href);
      console.log(this.shortLink)
      //   如果链接上有n位字符串就调用接口
      if (this.shortLink) {
          await this.getShortLink();
      }
  },
  methods: {
      getWeChatScheme() {
          if (!this.baseObj.path) {
              return;
          }
          this.$api.user.getWeChatScheme(this.baseObj).then(res => {
              console.log(res)
              window.location.href = res.scheme;
          })
      },
      getShortLink() {
          //N56Ym浏览器域名后面的5位字符串
          this.$api.user.getShortLink({'shortLink':this.shortLink,cliId:this.baseObj.cliId}).then(res => {
              console.log(res)
              this.baseObj = {
                  path: res.origLink,
                  cliId:res.cliId
              }
              this.$cache.set('baseObj', JSON.stringify(this.baseObj))
              this.getWeChatScheme();
          })
      },
      // 获取参数
      urlFmt(url) {
          // http://10.0.100.38:16525/32423#/
          let _url = `/${url.split('/')[3]}/${url.split('/')[4]}`;
          //——/32423#/
          let shortLink = _url.split('/')[1].split('#')[0]
          return shortLink;
      },
  }
};
</script>

<style lang="scss">
.active-box{
        // padding-top:86px;
        background:#fff;
        display:flex;
        flex-direction: column;
        justify-content: center;
        width:100%;
        height:100vh;
        text-align: center;
        .active-ludao{
            margin:0 auto;
            margin-bottom:30px;
            width:56px;
            height:56px;
            display:block;
        }
        .active-logo{
            width:215px;
            height:53px;
            display:block;
            margin:0 auto;
        }
        .active-text{
            // margin-top:252px;
            color:#151515;
            font-size:15px;
            font-weight:400;
        }
        .active-btn{
            width:201px;
            height:44px;
            margin:0 auto;
            background:#05C160;
            color:#fff;
            font-size:17px;
            margin-top:20px;
            font-weight:500;
            border:none;
            border-radius:4px;
        }
   }
   .fl86{
        display: flex;
        flex:86;
    }
    .fl30{
        display: flex;
        flex:30;
    }
    .fl252{
        display: flex;
        flex:252;
    }
    .fl206{
        display: flex;
        flex:206
    }
    .flsh0 {
        flex-shrink: 0;
    }
</style>
