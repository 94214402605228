import Vue from 'vue';

import 'vant/lib/index.css';
import { Button, Toast } from 'vant';
Vue.use(Button);
Vue.use(Toast);

// 公共组件
const requireComponents = require.context('@/components', true, /\.vue/);
requireComponents.keys().forEach((fileName) => {
  const reqCom = requireComponents(fileName);
  const reqComName = reqCom.name || fileName.replace(/\.\/(.*)\.vue/, '$1');
  Vue.component(reqComName, reqCom.default || reqCom);
});
