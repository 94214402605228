import Vue from 'vue';
import VueRouter from 'vue-router';

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    // {
    //   path: '/',
    //   redirect: '/index',
    // },
    {
      path: '/index',
      name: 'index',
      component: () => import('@/pages/index/index.vue'),
      meta: { title: 'index' },
    },
    {
      path: '/active',
      name: 'active',
      component: () => import('@/pages/active/activePage.vue'),
      meta: { title: 'active' },
    },
  ],
});

export default router;
