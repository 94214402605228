import { Toast } from 'vant';

let loadingCount = 0;

const ldloading = {
  show() {
    if (loadingCount === 0) {
      Toast.loading({
        forbidClick: true,
        message: '加截中...',
        loadingType: 'spinner',
      });
    }
    loadingCount += 1;
  },

  hide() {
    if (loadingCount <= 0) {
      return;
    }
    loadingCount -= 1;
    if (loadingCount === 0) {
      Toast.clear();
    }
  },
};

export default ldloading;
