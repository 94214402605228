
import http from '@/utils/http.js';
const user = {
  /**
   * @description: 登录
   * @return
   */
  login(params) {
    return http.post('/admin/permission/user/login', params);
  },
  /**
   * @description: 获取用户信息
   * @return
   */
  getUserInfo() {
    return http.post('/admin/permission/user/loginInfo');
  },
  /**
   * @description: 获取短链详情
   * @return
   */
  getShortLink(params) {
    return http.post('/app/v1/shortlink/info', params);
  },
  /**
   * @description: 获取打开微信小程序scheme
   * @return
   */
  getWeChatScheme(params) {
    return http.post('/app/v1/shortlink/wechat/scheme', params);
  }
};
export default user;
