import axios from 'axios';
import store from '@/store';
import { Toast } from 'vant';
import ldloading from './ld-loading';
import router from '@/router';

const readAsText = (file) => {
  return new Promise((resolve, reject) => {
    let fr = new FileReader();
    fr.readAsText(file);
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = (e) => reject(e);
  });
};

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000,
  headers: {
    'Content-type': 'application/json',
  },
});
/* 请求拦截 */
http.interceptors.request.use((config) => {
  config.headers['Authorization'] = store.getters.token || '';
  return config;
});

/* 返回拦截 */
http.interceptors.response.use(
  (response) => {
    //如果需要返回原始根数据
    //使用http.request方式，config配置传入custom==true
    if (response.config.custom) {
      return response;
    } else {
      // console.log(response.config.method,response.config.baseURL+response.config.url,response.data.data);
      //取消全局loading
      ldloading.hide();
      if ('data' in response.data) return response.data.data;
      else return response.data;
    }
  },
  (error) => {
    //自定义处理方式
    if (error.config.custom) {
      return Promise.reject(error);
    }
    //取消全局loading
    ldloading.hide();
    //统一处理错误
    if (error.response.status == 401 || error.response.status == 402) {
      //token失效处理方式
      // store.dispatch('user/logout').then(() => {
      //   router.replace('/login');
      //   location.reload(); //重置路由
      // });
      // Toast('请重新登录');
    } else if (error.response.status >= 500 && error.response.status < 600) {
      Toast('网络异常，请稍后重试！');
    } else if (error.response && error.response.data) {
      // xhr responseType 设置为 blob 但服务端报错时会返回 json 字符串, 此时 js 中的 resBody 为 blob 类型，需要手动转换为 json 对象
      let isContentTypeJSON = error.response.headers['content-type'].includes('application/json');
      let isResponseTypeBlob = error.response.data instanceof Blob;
      if (isContentTypeJSON && isResponseTypeBlob) {
        readAsText(error.response.data, 'utf8').then((changeRes) => {
          let errorResponse = JSON.parse(changeRes);
          Toast(errorResponse.msg);
        });
      } else {
        Toast(error.response.data.msg);
      }
    } else {
      Toast(error);
    }
    return Promise.reject(error);
  }
);
export default http;
