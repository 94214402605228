import Cookies from 'js-cookie';

const tokenKey = 'server-token';
const userInfoKey = 'server-userInfo';
const baseObj = 'baseObj'

export default {
  tokenKey,
  userInfoKey,
  baseObj,

  set(key, value) {
    if (key === tokenKey) {
      return Cookies.set(key, value);
    } else {
      return window.localStorage.setItem(key, value);
    }
  },
  get(key) {
    if (key === tokenKey) {
      return Cookies.get(key);
    } else {
      return window.localStorage.getItem(key);
    }
  },
  remove(key) {
    if (key === tokenKey) {
      return Cookies.remove(key);
    } else {
      return window.localStorage.removeItem(key);
    }
  },
};
