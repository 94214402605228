import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user.js';

Vue.use(Vuex);

const modules = {
  user,
};

const getters = {
  token: (state) => state.user.token,
};

const store = new Vuex.Store({
  modules,
  getters,
});

export default store;
